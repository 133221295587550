import moment from 'moment-timezone';
import FileSaver from 'file-saver';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import fetch from 'node-fetch';
import { Cookies } from 'react-cookie';

const customFormatDate = (date, format) => moment(date, format).toDate();
const formatDate = date => moment(date).format('MMMM D[,] YYYY');
const formatDateWithTimeZone = (date, timeZone) => {
  if (timeZone) {
    return moment(date)
      .tz(timeZone)
      .format('MMM DD, YYYY hh:mm a z');
  } else {
    return moment(date).format('MMM DD, YYYY hh:mm a z');
  }
};

const compatibleForDatepicker = date => (date ? moment(date) : null);
const formatDateForBackend = date => (date ? date.format() : null);
const formatDateForDeadline = date => (date ? date.format('LL') : null);
const checkDeadlinePast = date => {
  const nowDate = moment().format('MMMM D, YYYY h:mm a');
  const dateToCheck = moment(new Date(date)).format('MMMM D, YYYY h:mm a');
  return moment(dateToCheck).isSameOrBefore(nowDate);
};

const generateRangeArray = (start, stop, step) =>
  Array.from({ length: (stop - start) / step }, (_, i) => start + i * step);

const generateRandomImageIndex = (images = 5) => Math.floor(Math.random() * (images - 1)) + 0;

const emptyStringOrValue = value => (!!value && value.toString().trim().length > 0 ? value.toString() : '');
const emptyIntOrValue = value => (!!value && value.toString().trim().length > 0 ? parseInt(value) : '');
const emptyFloatOrValue = value => (!!value && value.toString().trim().length > 0 ? parseFloat(value) : '');
const emptyOrFormattedDate = value => (moment(value).isValid() ? moment(value).format('MMM D, YYYY') : '');
const numberCommaFormat = value => new Intl.NumberFormat().format(value);
const replaceCustomAliasesHandlebars = (string, customAliases) => {
  return string
    .replace(/{{Program}}/g, customAliases.alias_program)
    .replace(/{{Traveler}}/g, customAliases.alias_traveler)
    .replace(/{{Travelers}}/g, customAliases.alias_travelers)
    .replace(/{{Programs}}/g, customAliases.alias_programs)
    .replace(/{{program}}/g, customAliases.alias_program.toLowerCase())
    .replace(/{{traveler}}/g, customAliases.alias_traveler.toLowerCase())
    .replace(/{{travelers}}/g, customAliases.alias_travelers.toLowerCase())
    .replace(/{{programs}}/g, customAliases.alias_programs.toLowerCase());
  // .replace(/(?<!General )Form/g, 'General Form')
  // .replace(/(?<!general )form/g, 'general form');
};

const stripHardReturns = value => {
  if (!value || value === null) {
    value = '';
  }

  value = value.replace(/(?:\\[rn]|[\r\n]+)+/g, '');

  return value;
};
const stripHtmlString = value => {
  if (!value || value === null) {
    value = '';
  }

  value = value.toString();

  while (value !== null && value.includes('&nbsp;')) {
    value = value.replace('&nbsp;', '');
  }
  while (value !== null && value.includes('&amp;')) {
    value = value.replace('&amp;', '');
  }
  return value !== null && value.replace(/(<([^>]+)>)/gi, '');
};

const stripHtmlStringValidation = value => {
  if (!value || value === null) {
    value = '';
  }

  value = value.toString();

  while (value !== null && value.includes('&nbsp;')) {
    value = value.replace('&nbsp;', '_');
  }
  while (value !== null && value.includes('&amp;')) {
    value = value.replace('&amp;', '_');
  }
  return value !== null && value.replace(/(<([^>]+)>)/gi, '');
};

const hTag_To_pTag = value => {
  if (!value || value === null) {
    value = '';
  }
  while (value !== null && value.includes('&nbsp;')) {
    value = value.replace('&nbsp;', ' ');
  }

  value = value.replaceAll('h1>', 'p>');
  value = value.replaceAll('h2>', 'p>');
  value = value.replaceAll('h3>', 'p>');
  value = value.replaceAll('h4>', 'p>');
  value = value.replaceAll('h5>', 'p>');
  value = value.replaceAll('h6>', 'p>');

  return value !== null && value;
};

const buildSelectOptions = (values, sort = false, unique = false) => {
  let valueOptions = [];
  if (values && values.length > 0) {
    values = unique ? _uniq(values) : values;

    values = sort ? _orderBy(values, [value => value.toLowerCase()], ['asc']) : values;

    values.forEach(item => {
      valueOptions.push({
        label: item,
        value: item,
      });
    });
  }

  return valueOptions;
};

const buildCountyAndCityArray = locations => {
  let cityList = [];
  let countryList = [];

  if (locations?.length > 0) {
    let arrLocations = locations.split(';');
    arrLocations.forEach(loc => {
      let separate = loc.split(',');
      if (!separate[1]) {
        countryList.push(separate[0].trim());
      } else {
        if (separate[0]) {
          cityList.push(separate[0].trim());
        }
        if (separate[1]) {
          countryList.push(separate[1].trim());
        }
      }
    });
  }

  cityList = _uniq(cityList);
  cityList = _sortBy(cityList);

  countryList = _uniq(countryList);
  countryList = _sortBy(countryList);

  return {
    cities: cityList,
    countries: countryList,
    formattedCities: cityList.join('; '),
    formattedCountries: countryList.join('; '),
  };
};

const getContrastColor = hexcolor => {
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

const validateEmail = (email = '', errorMsg) => {
  let validEmail = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  return email.length < 3
    ? { valid: false, error: errorMsg ? errorMsg : 'Email must be entered' }
    : validEmail === null
    ? { valid: false, error: 'Invalid email address format' }
    : { valid: true, error: '' };
};

const isValidURL = str => {
  const regexp = /^http(s?):\/\/(www\.)?(((\w+(([\.\-]{1}([a-z]{2,})+)+)(\/[a-zA-Z0-9\_\=\?\&\.\#\-\W]*)*$)|(\w+((\.([a-z]{2,})+)+)(\:[0-9]{1,5}(\/[a-zA-Z0-9\_\=\?\&\.\#\-\W]*)*$)))|(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(([0-9]|([1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]+)+)(\/[a-zA-Z0-9\_\=\?\&\.\#\-\W]*)*)((\:[0-9]{1,5}(\/[a-zA-Z0-9\_\=\?\&\.\#\-\W]*)*$)*))$/;
  return regexp.test(str);
};

const downloadFile = (file, name) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  fetch(`${file}`, {
    headers: token,
  })
    .then(function(response) {
      return response.blob();
    })
    .then(function(blob) {
      FileSaver.saveAs(blob, name.replace(/ /g, '_'));
    });
};

const dateInUTC = () => {
  const now = new Date();
  const now_utc = new Date(now.toUTCString().slice(0, -4));
  const dateNowUTC = new Date(now_utc);
  const year = dateNowUTC.getFullYear();
  const month = ('0' + (dateNowUTC.getMonth() + 1)).slice(-2);
  const day = ('0' + dateNowUTC.getDate()).slice(-2);

  const dateUtc = `${year}-${month}-${day}`;

  return dateUtc;
};

const getDateFromOffsetDays = (date, dayOffset) => {
  let targetDate = date;

  if (dayOffset > 0) {
    targetDate = moment(date)
      .add(dayOffset, 'day')
      .format('YYYY-MM-DD');
  }

  if (dayOffset < 0) {
    targetDate = moment(date)
      .subtract(dayOffset * -1, 'day')
      .format('YYYY-MM-DD');
  }

  return targetDate;
};
export {
  checkDeadlinePast,
  buildSelectOptions,
  generateRandomImageIndex,
  formatDate,
  formatDateWithTimeZone,
  compatibleForDatepicker,
  formatDateForBackend,
  generateRangeArray,
  formatDateForDeadline,
  emptyStringOrValue,
  emptyIntOrValue,
  stripHtmlString,
  stripHtmlStringValidation,
  numberCommaFormat,
  emptyOrFormattedDate,
  emptyFloatOrValue,
  buildCountyAndCityArray,
  getContrastColor,
  replaceCustomAliasesHandlebars,
  hTag_To_pTag,
  validateEmail,
  isValidURL,
  stripHardReturns,
  downloadFile,
  dateInUTC,
  getDateFromOffsetDays,
  customFormatDate,
};
