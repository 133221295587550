import moment from 'moment-timezone';
import _cloneDeep from 'lodash/cloneDeep';

import {
  FETCH_GRAPH_INFORMATION,
  FETCH_GRAPH_PROGRAM,
  FETCH_ORGANIZATION_INSIGHTS,
  FETCH_ORGANIZATION_INFORMATION,
  FETCH_POPULAR_PROGRAMS,
  FETCH_RECENT_CONNECTS,
  FETCH_ANALYTICS_REPORT,
} from '../actions/types';

const initialState = {
  graphInformation: [],
  propularPrograms: [],
  recentConnects: [],
  programGraphInformation : [],
  organizationsList : [],
  organizationDetails : [],
  loading: true,
  donutChart: [],
  barChart: [],
  geoChart: [],
  reports: [],
};

export default function(state = initialState, action) {
  const recentConnectData = data => {
    return data.data.map(item => {
      return {
        id: item.attributes.id,
        action: item.attributes.action,
        user_email: item.attributes.user_email,
        action_date: moment(new Date(item.attributes.action_date)).format('MMM DD, YYYY'),
        program_name: item.attributes.program_name,
        full_name: item.attributes.full_name || item.attributes.user_email,
      };
    });
  };

  const analyticsReportData = data => {
    const transformedArray = data?.data?.map(originalObject => {
      const actionTime = originalObject.attributes.action_time;
      originalObject.attributes.timezone = moment(actionTime).format('Z');
      originalObject.attributes.action_time = moment(actionTime).format('hh:mm A');
      const { id, type, attributes } = originalObject;
      return { id, type, ...attributes };
    });

    return transformedArray;
  };

  switch (action.type) {
    case FETCH_GRAPH_INFORMATION:
      return {
        ...state,
        graphInformation: action.payload,
        donutChart: action.payload.doughnut,
        barChart: action.payload.bar,
        geoChart: action.payload.geo,
        loading: false,
      };
    
    case FETCH_GRAPH_PROGRAM:
      return {
        ...state,
        programGraphInformation: action.payload,
        loading: false,
      };  

    case FETCH_ORGANIZATION_INSIGHTS:
      return {
        ...state,
        organizationsList: action.payload,
        loading: false,
      };    

    case FETCH_ORGANIZATION_INFORMATION:
      return {
        ...state,
        organizationDetails: action.payload,
        loading: false,
      };   


    case FETCH_POPULAR_PROGRAMS:
      return {
        ...state,
        propularPrograms: action.payload,
        loading: false,
      };

    case FETCH_RECENT_CONNECTS:
      return {
        ...state,
        recentConnects: recentConnectData(action.payload),
        loading: false,
      };
    case FETCH_ANALYTICS_REPORT:
      return {
        ...state,
        reports: analyticsReportData(action.payload),
        loading: false,
      };
    default:
      return state;
  }
}
