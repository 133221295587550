import React from 'react';
import { compose } from 'recompose';
import { LicenseManager } from 'ag-grid-enterprise';

const agGridKey =
  'Using_this_AG_Grid_Enterprise_key_( AG-050692 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Via TRM )_is_granted_a_( Single Application )_Developer_License_for_the_application_( VIA TRM )_only_for_( 3 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( VIA TRM )_need_to_be_licensed___( VIA TRM )_has_been_granted_a_Deployment_License_Add-on_for_( 2 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 13 November 2024 )____[v2]_MTczMTQ1NjAwMDAwMA==480fe7d27a570e9c5fdd58f9f6fa9962';
LicenseManager.setLicenseKey(agGridKey);
export const enhance = compose();

function AgGridKey() {
  return <></>;
}

export default enhance(AgGridKey);
